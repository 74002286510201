const Admin_Panel = 'info@amazonbookwriter.com';
const PHONE = '(844) 237-2738';
const PHONE_HREF = 'tel:8442372738';
const EMAIL = 'info@amazonbookwriter.com';
const EMAIL_HREF = 'mailto:info@amazonbookwriter.com';
const CONTACT_EMAIL = 'info@amazonbookwriter.com';
const CONTACT_EMAIL_HREF = 'mailto:info@amazonbookwriter.com';
const ADDRESS = '1004 Broadway, Oakland, CA 94607, USA';
const WEBSITE_NAME = 'Amazon Book Writer';
const SITE_CURRENCY_SYMBOLS = '$';
const WEBSITE_LOGO =
  '';

export {
  Admin_Panel,
  PHONE,
  PHONE_HREF,
  EMAIL,
  EMAIL_HREF,
  CONTACT_EMAIL,
  CONTACT_EMAIL_HREF,
  ADDRESS,
  WEBSITE_NAME,
  SITE_CURRENCY_SYMBOLS,
  WEBSITE_LOGO,
};
