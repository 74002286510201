import React from "react";
import OwlCarousel from 'react-owl-carousel';

import {
    WEBSITE_NAME,
} from '../../config';

export default function TestimonialSlider() {
    var settings = {
        loop:!0,margin:0,nav:!1,dots:!0,margin:10,items:2,auto:!0,responsiveClass:!0,responsive:{0:{items:1,nav:!1},600:{items:1,nav:!1},1e3:{items:2,nav:!1,loop:!1}}
     }
  return (
    <OwlCarousel class="owl-carousel owl-theme" id="testimonails-slider" {...settings}>
        <div class="item">
                <p>{WEBSITE_NAME} made my dream of becoming an author come true. They are some
                    crazy creative people with a lot of ideas and took care of everything from outlining the
                    plot to designing and publishing. They revised the draft times and again to my
                    satisfaction.</p>
                <h6>Martha Doyle <span>Educationist and Author</span></h6>
            </div>
            <div class="item">
                <p>I was referred to {WEBSITE_NAME} by a friend – and I couldn't thank him
                    enough for this. The professionals at {WEBSITE_NAME} took time to understand
                    my requirements and took it to their heart to convert my book idea into a published
                    reality.</p>
                <h6>Evan Bruce <span>Author</span></h6>
            </div>
    </OwlCarousel>
    
  );
} 