import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

const usePageTitle = () => {
  const location = useLocation();

  useEffect(() => {
    const setMetaTags = () => {
      let title;
      let description = 'Amazon Book Writer elevates your book with our comprehensive services. Expert ghostwriting, professional publishing, and meticulous editing.';
      const url = window.location.href;

      switch (location.pathname) {
        case '/':
          title = 'Amazon Book Writer - Hire Top Ghostwriters at 85% Off to Create Your Timeless Work';
          break;
        default:
          title = 'Amazon Book Writer - Hire Top Ghostwriters at 85% Off to Create Your Timeless Work';
          description = 'Amazon Book Writer elevates your book with our comprehensive services. Expert ghostwriting, professional publishing, and meticulous editing.';
      }

      document.title = title;

      return (
        <Helmet>
          <title>{title}</title>
          <meta name="description" content={description} />
          <meta property="og:title" content={title} />
          <meta property="og:description" content={description} />
          <meta property="og:url" content={url} />
        </Helmet>
      );
    };

    setMetaTags();
  }, [location.pathname]);

  return null;
};

export default usePageTitle;
