import React from "react";
import OwlCarousel from 'react-owl-carousel';
  import {
    BookImg1,
    BookImg2,
    BookImg3,
    BookImg4,
    BookImg5,
    BookImg6,
    BookImg7,
    BookImg8,
    BookImg9,
    BookImg10,
    BookImg11,
    BookImg12,
    BookImg13,
    BookImg14,
    BookImg15,
    BookImg16,
    BookImg17,
    BookImg18,
  } from '../../assets/images';

export default function PortfolioSlider() {
    var settings = {
        loop: !0,
        nav: !1,
        dots: true,
        margin: 10,
        responsiveClass: !0,
        autoplay: !0,
        autoplayHoverPause: !0,
        autoplayTimeout: 4e3,
        autoplaySpeed: 400,
        navText: [""],
        responsive: { 0: { items: 4 }, 1920: { items: 4 }, 1200: { items: 3 }, 768: { items: 2 }, 576: { items: 1 } }
     }
  return (
    <OwlCarousel class="whatlogoslider owl-carousel owl-theme" {...settings}>
                    <div class="item">
                        <div class="image-boxwrpr">
                            <img src={BookImg1}/>
                            <div class="content-wrpr">
                                <p>R. C. Sproul, in this classic theological work, puts the holiness of God in its proper
                                    and central place in the Christian life. Drawing from both the Old Testament and the New
                                    Testament, he paints an awe-inspiring vision of God that encourages Christians to become
                                    holy just as God is holy. With depth and eloquence, this timely book addresses:</p>
                                <ul>
                                    <li>What Sproul calls the “trauma of holiness”</li>
                                    <li>How we reconcile God’s justice with his love</li>
                                    <li>How to know if we are moving forward in our pursuit of righteousness
                                        Once you encounter the holiness of God, your life will never be the same.</li>
                                </ul>
                                <span class="one"></span>
                                <span class="two"></span>
                                <span class="three"></span>
                                <span class="four"></span>
                            </div>  
                        </div>
                    </div>
                    <div class="item">
                        <div class="image-boxwrpr">
                            <img src={BookImg2}/>
                            <div class="content-wrpr">
                                <p>In Believing Is Seeing, Dr. Guillen recounts the fascinating story of his journey from
                                    Atheism to Christianity, citing the latest discoveries in neuroscience, physics,
                                    astronomy, and mathematics to pull back the curtain on the mystery of faith as no one
                                    ever has.
                                </p>
                                <p>Is it true that “seeing is believing?” Or is it possible that reality can be perceived
                                    most clearly with the eyes of faith―and that truth is bigger than proof? Let Dr. Guillen
                                    be your guide as he brilliantly argues for a large and enlightened worldview consistent
                                    with both God and modern science.</p>
                                <span class="one"></span>
                                <span class="two"></span>
                                <span class="three"></span>
                                <span class="four"></span>
                            </div>
                        </div>
                    </div>
                    <div class="item">
                        <div class="image-boxwrpr">
                            <img src={BookImg3}/>
                            <div class="content-wrpr">
                                <p>In Kingdom Focus, Tony Evans teaches readers how to focus on the things God Himself
                                    focuses on in Scripture and explores the issues that delight His heart. It is by doing
                                    so that we can discover the joy and the power of delighting in the same things. Allow
                                    God to sharpen your focus, transform your mind, and empower you to live every aspect of
                                    your life in light of the kingdom to which you have been chosen and called, for such a
                                    time as this.</p>
                                <strong>Learn how to</strong>
                                <ul>
                                    <li>examine the spiritual habits we can employ to sharpen our focus for God’s kingdom,
                                    </li>
                                    <li>fully maximize life while on earth,</li>
                                    <li>delve deeper into the character and heart of God, and</li>
                                    <li>recognize how God’s love for us guides us every step of the way.</li>
                                </ul>
                                <span class="one"></span>
                                <span class="two"></span>
                                <span class="three"></span>
                                <span class="four"></span>
                            </div>
                        </div>
                    </div>
                    <div class="item">
                        <div class="image-boxwrpr">
                            <img src={BookImg4}/>
                            <div class="content-wrpr">
                                <p>As a CIA agent and a counterterrorism expert, Michele soon found that working undercover
                                    was an all-encompassing job. The threats were real; the assignments perilous. Michele
                                    spent over a decade in the agency―a woman leading some of the most highly skilled
                                    operatives on the planet, secretly serving in some of the most treacherous areas of the
                                    Middle East, and at risk as a target for ISIS. But deep inside, Michele wondered: Could
                                    she really do this job? Had she misunderstood what she thought was God’s calling on her
                                    life? Did she have what it would take to survive?</p>
                                <p>The answer came when Michele faced her ultimate mission, one with others’ lives on the
                                    line―and it turned out to have been the plan for her all along. In Breaking Cover,
                                    Michele has at last been cleared to drop cover and tell her story: one of life-or-death
                                    stakes; of defeating incredible odds; and most of all, of discovering a faith greater
                                    than all her fears.</p>
                                <span class="one"></span>
                                <span class="two"></span>
                                <span class="three"></span>
                                <span class="four"></span>
                            </div>
                        </div>
                    </div>
                    <div class="item">
                        <div class="image-boxwrpr">
                            <img src={BookImg5}/>
                            <div class="content-wrpr">
                                <p>America teeters on a precipice. In the midst of financial turmoil, political uncertainty,
                                    declining morality, the constant threat of natural disasters, and myriad other daunting
                                    challenges, many wonder what the future holds. Will history’s greatest democracy stage a
                                    miraculous comeback, returning to the forefront of the world’s economic and spiritual
                                    stage? Can America’s religious past be repeated today with a third Great Awakening? Or
                                    will the rise of China, Russia, and other nations, coupled with the US’s internal
                                    struggles, send her into a decline from which there can be no return?</p>
                                <p>Implosion helps readers understand the economic, social, and spiritual challenges facing
                                    the United States in the 21st century, through the lens of biblical prophecy.</p>
                                <span class="one"></span>
                                <span class="two"></span>
                                <span class="three"></span>
                                <span class="four"></span>
                            </div>
                        </div>
                    </div>
                    <div class="item">
                        <div class="image-boxwrpr">
                            <img src={BookImg6}/>
                            <div class="content-wrpr">
                                <p>It was a most unlikely prediction. Perceived as a failure for much of his life, Churchill
                                    was the last person anyone would have expected to rise to national prominence as prime
                                    minister and influence the fate of the world during World War II. But Churchill
                                    persevered, on a mission to achieve his purpose. God and Churchill tells the remarkable
                                    story of how one man, armed with belief in his divine destiny, embarked on a course to
                                    save Christian civilization when Adolf Hitler and the forces of evil stood opposed. It
                                    traces the personal, political, and spiritual path of one of history’s greatest leaders
                                    and offers hope for our own violent and troubled times.</p>
                                <span class="one"></span>
                                <span class="two"></span>
                                <span class="three"></span>
                                <span class="four"></span>
                            </div>
                        </div>
                    </div>
                    <div class="item">
                        <div class="image-boxwrpr">
                            <img src={BookImg7}/>
                            <div class="content-wrpr">

                                <strong>10 Principles:</strong>
                                <ul>
                                    <li>Ditch the Distractions
                                    </li>
                                    <li>Align with Reality (never claim support for a bad cause)</li>
                                    <li>Find Symmetry</li>
                                    <li>Respect the Wise</li>
                                    <li>Protecct the Souls</li>
                                    <li>Commit to the Relationships</li>
                                    <li>Spread the Wealth</li>
                                    <li>Speak the Truth</li>
                                    <li>Limit Your Desires</li>
                                </ul>
                                <span class="one"></span>
                                <span class="two"></span>
                                <span class="three"></span>
                                <span class="four"></span>
                            </div>
                        </div>
                    </div>
                    <div class="item">
                        <div class="image-boxwrpr">
                            <img src={BookImg8}/>
                            <div class="content-wrpr">

                                <p>As a former Super Bowl–winning coach, Tony Dungy is an expert at building and bringing
                                    out the best in a team. Drawing on his experiences from years of coaching and working
                                    with other leaders, this football fable lays out four essential principles practiced by
                                    truly effective teams. Telling the story of a fictional NFL team looking for a
                                    turnaround, The Soul of a Team not only identifies some of the most common issues that
                                    hold a team back but also lays out a game plan for winning teamwork. Whether you aspire
                                    to be a better leader or a stronger team player, The Soul of a Team will show you how to
                                    contribute to a stronger, healthier, more productive team destined for success.</p>
                                <span class="one"></span>
                                <span class="two"></span>
                                <span class="three"></span>
                                <span class="four"></span>
                            </div>
                        </div>
                    </div>
                    <div class="item">
                        <div class="image-boxwrpr">
                            <img src={BookImg9}/>
                            <div class="content-wrpr">
                                <p>If we don’t learn to work together and stick together around these 12 sticking points,
                                    then we’ll be wasting a lot of time fighting each other instead of enjoying a friendly
                                    and productive team.
                                </p>
                                <p>Sticking Points is a must-read book with insights that will help you understand the
                                    generational differences you encounter. It also teaches us how we can learn to speak one
                                    another’s language, engage with different generational personalities, and get better
                                    results together.</p>
                                <span class="one"></span>
                                <span class="two"></span>
                                <span class="three"></span>
                                <span class="four"></span>
                            </div>
                        </div>
                    </div>
                    <div class="item">
                        <div class="image-boxwrpr">
                            <img src={BookImg10}/>
                            <div class="content-wrpr">
                                <p>Elianna is a young girl crushed by guilt. After her only brother is killed while in her
                                    care, Elianna tries to earn forgiveness by working for her father’s textile trade and
                                    caring for her family. When another tragedy places Elianna in sole charge of the
                                    business, her talent for design brings enormous success, but never the absolution she
                                    longs for. As her world unravels, she breaks off her betrothal to the only man she will
                                    ever love. Then illness strikes, isolating Elianna from everyone, stripping everything
                                    she has left.

                                </p>
                                <p>No physician can cure her. No end is in sight. Until she hears whispers of a man whose
                                    mere touch can heal. After so many years of suffering and disappointment, is it possible
                                    that one man could redeem the wounds of body . . . and soul?</p>
                                <span class="one"></span>
                                <span class="two"></span>
                                <span class="three"></span>
                                <span class="four"></span>
                            </div>
                        </div>
                    </div>
                    <div class="item">
                        <div class="image-boxwrpr">
                            <img src={BookImg11}/>
                            <div class="content-wrpr">
                                <p>Robert Langdon, Harvard professor of symbology, arrives at the ultramodern Guggenheim
                                    Museum Bilbao to attend the unveiling of a discovery that “will change the face of
                                    science forever.” The evening’s host is Edmond Kirsch, a forty-year-old billionaire and
                                    futurist, and one of Langdon’s first students.
                                </p>
                                <p>But the meticulously orchestrated evening suddenly erupts into chaos, and Kirsch’s
                                    precious discovery teeters on the brink of being lost forever. Facing an imminent
                                    threat, Langdon is forced to flee. With him is Ambra Vidal, the elegant museum director
                                    who worked with Kirsch. They travel to Barcelona on a perilous quest to locate a cryptic
                                    password that will unlock Kirsch’s secret.</p>
                                <span class="one"></span>
                                <span class="two"></span>
                                <span class="three"></span>
                                <span class="four"></span>
                            </div>
                        </div>
                    </div>
                    <div class="item">
                        <div class="image-boxwrpr">
                            <img src={BookImg12}/>
                            <div class="content-wrpr">
                                <ul>
                                    <li> <strong>Aaron―</strong> the priest who stood in Moses’ shadow but had the courage
                                        to cover his brother’s
                                        fears.</li>
                                    <li><strong>Caleb―</strong> the warrior whose words stirred men’s hearts and brought
                                        God’s people to the
                                        Promised Land.</li>
                                    <li><strong> Jonathan―</strong> the prince whose humility led him to befriend the man
                                        who would become king in
                                        his place.</li>
                                    <li><strong>Amos―</strong>the prophet who heard when God called and spoke to a nation
                                        unwilling to listen.</li>
                                    <li><strong>Silas―</strong>the scribe who surrendered his wealth to record God’s Word,
                                        even as those around
                                        him were silenced.</li>
                                </ul>
                                <p>Each novella includes an in-depth Bible study perfect for personal reflection or group
                                    discussion.</p>
                                <span class="one"></span>
                                <span class="two"></span>
                                <span class="three"></span>
                                <span class="four"></span>
                            </div>
                        </div>
                    </div>
                    <div class="item">
                        <div class="image-boxwrpr">
                            <img src={BookImg13}/>
                            <div class="content-wrpr">
                                <p>Explore the wonders of faith and science with 52 exciting experiments designed for kids
                                    and parents to enjoy together. Each experiment―carefully curated by our quirky guide,
                                    Dr. Fizzlebop―offers a delightful blend of learning and fun, making it perfect for
                                    weekly family adventures or convenient exploration at your own pace.</p>
                                <ul>
                                    <li> 52 easy experiments and engaging devotions</li>
                                    <li>Full-color illustrations</li>
                                    <li>Easy step-by-step guides</li>
                                    <li>Easy prep with curated supply lists</li>
                                </ul>
                                <p >Embrace Dr. Fizzlebop’s enthusiasm as he leads you through a
                                    fizztastic journey at Fizzlebop Labs. Dr. Fizzlebop’s love for God, science, and fizz
                                    shines through in every experiment.</p>
                                <span class="one"></span>
                                <span class="two"></span>
                                <span class="three"></span>
                                <span class="four"></span>
                            </div>
                        </div>
                    </div>
                    <div class="item">
                        <div class="image-boxwrpr">
                            <img src={BookImg14}/>
                            <div class="content-wrpr">
                                <ul>
                                    <li>Fast facts highlighting the animal’s scientific name, animal family name, size, and
                                        diet</li>
                                    <li>An animal-themed devotional bursting with astounding discoveries and spiritual
                                        truths</li>
                                    <li>Journaling space to unpack real-life applications</li>
                                    <li>A memory verse and sample prayer to connect with God</li>
                                    <li>A wild wonder fun fact with a humorous animal doodle</li>
                                    <li>A creature connection activity like a craft, game, or snacks</li>
                                </ul>
                                <p >The perfect blend of Scripture and science, this exciting and
                                    informative devotional will expand the minds and grow the curiosity of your young ones.
                                    A fantastic gift for the little animal lover in your life.</p>
                                <span class="one"></span>
                                <span class="two"></span>
                                <span class="three"></span>
                                <span class="four"></span>
                            </div>
                        </div>
                    </div>
                    <div class="item">
                        <div class="image-boxwrpr">
                            <img src={BookImg15}/>
                            <div class="content-wrpr">
                                <p>Devotional time is usually “quiet time”―a moment to sit and reflect on Scripture and
                                    connect with God. Hands-On Bible: 365 Devotions for Kids will reignite and energize your
                                    family devotions. Your kids will be giggling, singing, dancing, creating, and getting
                                    excited about the Bible all while praising God and growing closer to Him. Each of the
                                    365 action-filled devotions comes to life with fun activities and games using regular
                                    household items. No need to run out and buy extra supplies! With Hands-On Bible: 365
                                    Devotions for Kids, your children will get into the Bible, and the Bible will get into
                                    their hearts, giving them a priceless foundation of faith in God.</p>
                                <span class="one"></span>
                                <span class="two"></span>
                                <span class="three"></span>
                                <span class="four"></span>
                            </div>
                        </div>
                    </div>
                    <div class="item">
                        <div class="image-boxwrpr">
                            <img src={BookImg16}/>
                            <div class="content-wrpr">
                                <p>Pawverbs for a Dog Lover’s Heart is a collection of dog stories, with each reading
                                    featuring:</p>
                                <ul>
                                    <li>Beautiful photographs of real-life animals</li>
                                    <li>A principle from the book of Proverbs</li>
                                    <li>Journaling space to unpack real-life applications</li>
                                    <li>Prompts to ponder and pray through</li>
                                </ul>
                                <p>In this devotional, you’ll not only find inspiring and heartwarming tales, but spiritual
                                    truths to touch your heart and remind you of God’s unfailing love, wisdom, and grace.
                                </p>
                                <strong>The perfect gift for dog lovers!</strong>
                                <span class="one"></span>
                                <span class="two"></span>
                                <span class="three"></span>
                                <span class="four"></span>
                            </div>
                        </div>
                    </div>
                    <div class="item">
                        <div class="image-boxwrpr">
                            <img src={BookImg17}/>
                            <div class="content-wrpr">

                                <ul>
                                    <li>Inspire you to discover a dream you didn’t know you had</li>
                                    <li>Reassure you that whether you’re building a career, raising a family, or redoing
                                        your home, it’s okay to slow down and enjoy the journey</li>
                                    <li>Teach you practical ideas for combining work and family―and how to work in harmony
                                        with adult children</li>
                                    <li>Reveal her pro tips for making your home a beautiful place to be</li>
                                    <li>Provide gorgeous 4-color photos of her classic, elegant designs for your own
                                        inspiration</li>
                                </ul>
                                <p>Any home―and life―can become even more beautiful with a little bit of heart.</p>
                                <span class="one"></span>
                                <span class="two"></span>
                                <span class="three"></span>
                                <span class="four"></span>
                            </div>
                        </div>
                    </div>
                    <div class="item">
                        <div class="image-boxwrpr">
                            <img src={BookImg18}/>
                            <div class="content-wrpr">

                                <p>Gretchen Saffles knows what it's like to feel overwhelmed and unable to flourish. In The
                                    Well-Watered Woman, Gretchen leads us to the Well of fullness, the Word of freedom, and
                                    the Way of fruitfulness. She teaches that God's Word will satisfy us for all eternity.
                                </p>
                                <p>Using Scripture and her own personal story of surrender, Gretchen offers spiritually
                                    hungry women tangible tools to not only know Jesus more but to live a life that
                                    thoroughly enjoys Him, seeks Him, and follows Him into freedom.</p>
                                <span class="one"></span>
                                <span class="two"></span>
                                <span class="three"></span>
                                <span class="four"></span>
                            </div>
                        </div>
                    </div>
    </OwlCarousel>
    
  );
} 