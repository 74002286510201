import './App.css';
import React, { useState, useEffect } from 'react';
import {
  Logo,
  LogoImg1,
  LogoImg2,
  LogoImg3,
  LogoImg4,
  Icon2,
  Icon1,
  Icon3,
  FeaturesImg,
  CtaBook,
  BtmLogo1,
  BtmLogo2,
  BtmLogo3,
  BtmLogo4,
  BtmLogo5,
  BtmLogo6,
  TopScroll,
} from './assets/images'
import {
  EMAIL,
  PHONE,
  PHONE_HREF,
  WEBSITE_NAME,
} from './config';
import Countdown from './components/Countdown';
import PortfolioSlider from './components/PortfolioSlider';
import TestimonialSlider from './components/TestimonialSlider';
import { ModalSection } from './layout/modalSection';
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

function App() {

  useEffect(() => {
    // Function to open chat window
    const openChatWindow = () => {
      if (window.$zopim) {
        window.$zopim.livechat.window.show();
      }
    };

    // Function to toggle chat window
    const toggleChat = () => {
      if (window.$zopim) {
        window.$zopim.livechat.window.toggle();
      }
    };

    // Initialize chat and open by default
    const initializeChat = () => {
      const intervalId = setInterval(() => {
        if (window.$zopim && window.$zopim.livechat) {
          clearInterval(intervalId);
          openChatWindow();
          listenForNewMessages();
        }
      }, 100); // Check every 100ms if chat widget is available
    };

    // Listen for new messages
    const listenForNewMessages = () => {
      if (window.$zopim && window.$zopim.livechat) {
        window.$zopim.livechat.setOnUnreadMsgs((numberOfUnreadMessages) => {
          if (numberOfUnreadMessages > 0) {
            openChatWindow();
          }
        });
      }
    };

    initializeChat();

    // Attach event listeners when the component mounts
    const chatButton = document.querySelector('.chat'); // Replace with actual button selector
    if (chatButton) {
      chatButton.addEventListener('click', toggleChat);
    }

    // Cleanup: Remove event listeners when the component unmounts
    return () => {
      if (chatButton) {
        chatButton.removeEventListener('click', toggleChat);
      }
    };
  }, []);

  const [open, setOpen] = useState(false);

  const onOpenModal = () => {
    setOpen(true);
  };

  const onCloseModal = () => {
    setOpen(false);
  };
  return (
    <>
      <header>
        <div class="container">
          <div class="row">
            <div class="col-md-6">
              <a href="javascript:;"><img alt="" class="header-logo" src={Logo} /></a>
            </div>
            <div class="col-md-6">
              <ul>
                <li class="last">
                  <a class="various" data-fancybox="" data-src="#popupform" onClick={onOpenModal} href="javascript:;" id="0"
                    name="0" title="Activate Coupon Now">Let's Get Started</a>
                </li>
                <li class="last">
                  <a class="various" href={PHONE_HREF}>{PHONE}</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </header>

      <section class="banner">
        <div class="container">
          <Countdown />
          <h1>Become a best selling Author!</h1>
          <p><span>HIRE Top Ranked Writers from {WEBSITE_NAME} AT 85% OFF</span><br /></p>
          <ul class="banner-features">
            <li><i class="fa fa-check"></i> 93% of Customers Hire</li>
            <li><i class="fa fa-check"></i> 2.5k+ WRITERS AND EDITORS</li>
            <li><i class="fa fa-check"></i> 100% SATISFACTION GUARANTEED</li>
            <li><i class="fa fa-check"></i> GHOSTWRITTEN UNDER THE SUPERVISION OF {WEBSITE_NAME}</li>
          </ul><a class="various" data-fancybox="" data-src="#popupform" onClick={onOpenModal} href="javascript:;" id="0" name="0"
            title="Activate Coupon Now">Activate your Coupon</a> <a class="chat" href="javascript:;">Chat now to get
              85% OFF</a>
          <ul class="banner-logos">
            <li><img alt="" src={LogoImg1} /></li>
            <li><img alt="" src={LogoImg2} /></li>
            <li><img alt="" src={LogoImg3} /></li>
            <li><img alt="" src={LogoImg4} /></li>
          </ul>
        </div>
      </section>

      <section class="banner-btm">
        <div class="container-fluid">
          <div className='row'>
            <div class="col-md-4">
              <i><img alt="" src={Icon2} /></i>
              <h6>Affordable<br />
                Prices</h6>
            </div>
            <div class="col-md-4">
              <i><img alt="" src={Icon1} /></i>
              <h6>Quickest<br />
                Turnaround Time</h6>
            </div>
            <div class="col-md-4">
              <i><img alt="" src={Icon3} /></i>
              <h6>24/7 Professional<br />
                Support</h6>
            </div>
          </div>
        </div>
      </section>

      <section class="hm-about">
        <div class="container">
          <h2>The world is waiting for your book</h2>
          <div class="row">
            <div class="col-md-8">
              <p>Every great advancement is born out of a fascinating work of imagination. We, at
                {WEBSITE_NAME}, will take that step forward for you. Every story deserves to be told
                and heard. Therefore, we invite you to experience the work of industry's most professional yet
                extremely innovative ghostwriters, all under one roof.<br />
                <br />
                We have a track record of delivering thousands of successful publications – meticulously
                written, edited, designed and published. Many of the published works we have done for our
                clients have created a buzz in the literary sphere and ranked among the best sellers.
                {WEBSITE_NAME} is the perfect choice to put your ideas on paper. We have writers who
                can help you tell your story, in any genre, from fiction to nonfiction.
              </p>
              <a class="various" data-fancybox="" data-src="#popupform" onClick={onOpenModal} href="javascript:;" id="0"
                title="Activate Coupon Now">Let's Get Started</a>
              <a class="various" href={PHONE_HREF}>{PHONE}</a>
            </div>
            <div class="col-md-4">
              <ul>
                <li><i class="fa fa-check"></i> Fiction</li>
                <li><i class="fa fa-check"></i> Non-Fiction</li>
                <li><i class="fa fa-check"></i> Biography</li>
                <li><i class="fa fa-check"></i> Informative</li>
                <li><i class="fa fa-check"></i> Autobiography</li>
                <li><i class="fa fa-check"></i> Memoirs</li>
                <li><i class="fa fa-check"></i> Action &amp; Adventure</li>
                <li><i class="fa fa-check"></i> Western</li>
                <li><i class="fa fa-check"></i> Anthology</li>
                <li><i class="fa fa-check"></i> Romance</li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <section class="portfolio">
        <div class="container-fluid">
          <h2>We have helped thousands of writers <br /> get their work published</h2>
          <p>Our diversified talent pool with multiple unique skillsets has enabled us to specialize in various <br />
            genres
            and cater to the ghostwriting needs of clients from varying industries and professional spheres.</p>
          <PortfolioSlider />
        </div>
      </section>

      <section class="pricing-sec">
        <div class="container">
          <h2>Pricing Plan</h2>
          <p>Our podium of {WEBSITE_NAME} services offers you with an array of experienced ghostwriters,
            editors and publishing eccentrics who will together, transform your idea into a splendid yet commendable
            book.</p>
          <div class="row">
            <div class="col-md-4 col-lg-3 px-1">
              <div>
                <div class="title-pkg">
                  <h3>Amazon's Best Seller</h3>
                  <h4>$5,999 <ins>$12,000</ins></h4>
                  <div id="grad1"></div>
                </div>
                <ul>
                  <li><img alt="" src="assets/images/greentick.png" />Writing &amp; Rewriting of up to 500 Pages
                  </li>
                  <li><img alt="" src="assets/images/greentick.png" />Planning / Creating/updating the outline
                  </li>
                  <li><img alt="" src="assets/images/greentick.png" />Ghostwriting original content based on
                    interviews or research</li>
                  <li><img alt="" src="assets/images/greentick.png" />Completion of your book as per the
                    requirements/outline.</li>
                  <li><img alt="" src="assets/images/greentick.png" />Revisions to our writing per your feedback
                    and guidance.</li>
                  <li><img alt="" src="assets/images/greentick.png" />Meticulous editing to ensure your text is
                    free of error.</li>
                  <li><img alt="" src="assets/images/greentick.png" />Adjusting the layout and presentation for
                    the publishing standards.</li>
                  <li><img alt="" src="assets/images/greentick.png" />Add Up to 20-30 Custom Illustrations.</li>
                  <li><img alt="" src="assets/images/greentick.png" />Adjusting the layout and presentation for
                    the publishing standards.</li>
                  <li><img alt="" src="assets/images/greentick.png" />Unlimited revisions.</li>
                  <li><img alt="" src="assets/images/greentick.png" />An attention-grabbing Book Cover.</li>
                  <li><img alt="" src="assets/images/greentick.png" />Turnaround Time of 60-80 Days.</li>
                  <li><img alt="" src="assets/images/greentick.png" />Rights to make changes to your book.</li>
                  <li><img alt="" src="assets/images/greentick.png" />Editing, Proofreading, and formatting.
                  </li>
                  <li><img alt="" src="assets/images/greentick.png" />Basic Author’s Website</li>
                  <li><img alt="" src="assets/images/greentick.png" />Authors Bio.</li>
                  <li><img alt="" src="assets/images/greentick.png" />Publishing on Amazon Kindle, Barnes &amp;
                    Noble, Kobo, Ingram Spark, E-Book Publishing (Any 3)</li>
                  <li><img alt="" src="assets/images/greentick.png" />Guaranteed 2000 Reads in 12 months</li>
                </ul>
                <h5><b>Note:</b> The Amazon Best Seller Package guarantees you at least $20,000 in revenue in
                  the first year after the book is published, and if we fail to meet this goal, we will fully
                  refund your money.</h5><a class="various" data-fancybox="" data-src="#popupform" onClick={onOpenModal}
                    href="javascript:;">Let's get started</a>
              </div>
            </div>
            <div class="col-md-4 col-lg-3 px-1">
              <div>
                <div class="title-pkg green-t">
                  <h3>Amazon Prime Package</h3>
                  <h4>$9,899 <ins>$19,798</ins></h4>
                  <div class="green-grad" id="grad1"></div>
                </div>
                <ul>
                  <li><img alt="" src="assets/images/greentick.png" />Writing &amp; Rewriting of up to 500 Pages
                  </li>
                  <li><img alt="" src="assets/images/greentick.png" />Unlimited Revisions</li>
                  <li><img alt="" src="assets/images/greentick.png" />3 Months of Paid Marketing on Google,
                    Facebook and Amazon</li>
                  <li><img alt="" src="assets/images/greentick.png" />Book Launch Globally</li>
                  <li><img alt="" src="assets/images/greentick.png" />Full Ownership Rights</li>
                  <li><img alt="" src="assets/images/greentick.png" />Planning / Creating/updating the outline
                  </li>
                  <li><img alt="" src="assets/images/greentick.png" />Ghostwriting original content based on
                    interviews or research</li>
                  <li><img alt="" src="assets/images/greentick.png" />Completion of your book as per the
                    requirements/outline.</li>
                  <li><img alt="" src="assets/images/greentick.png" />Revisions to our writing per your feedback
                    and guidance.</li>
                  <li><img alt="" src="assets/images/greentick.png" />Meticulous editing to ensure your text is
                    free of error.</li>
                  <li><img alt="" src="assets/images/greentick.png" />Adjusting the layout and presentation for
                    the publishing standards</li>
                  <li><img alt="" src="assets/images/greentick.png" />Add Up to 50-60 Custom Illustrations.</li>
                  <li><img alt="" src="assets/images/greentick.png" />Adjusting the layout and presentation for
                    the publishing standards.</li>
                  <li><img alt="" src="assets/images/greentick.png" />Unlimited revisions.</li>
                  <li><img alt="" src="assets/images/greentick.png" />An attention-grabbing Book Cover.</li>
                  <li><img alt="" src="assets/images/greentick.png" />Turnaround Time of 60 -80 Days.</li>
                  <li><img alt="" src="assets/images/greentick.png" />Rights to make changes to your book.</li>
                  <li><img alt="" src="assets/images/greentick.png" />Editing, Proofreading, and formatting.
                  </li>
                  <li><img alt="" src="assets/images/greentick.png" />Author Website</li>
                  <li><img alt="" src="assets/images/greentick.png" />Intro Book Video(Video Trailer)</li>
                  <li><img alt="" src="assets/images/greentick.png" />Authors Bio.</li>
                  <li><img alt="" src="assets/images/greentick.png" />Pitching article ideas to the press.</li>
                  <li><img alt="" src="assets/images/greentick.png" />Publishing on Amazon Kindle, Barnes &amp;
                    Noble, Kobo, Ingram Spark, iBook, and Lulu (Any 5 Platforms)</li>
                  <li><img alt="" src="assets/images/greentick.png" />Available in 3 different formats E-Book,
                    and Paper Back, Hardcover</li>
                  <li><img alt="" src="assets/images/greentick.png" />Amazon will be responsible for printing
                    and shipping your book globally</li>
                  <li><img alt="" src="assets/images/greentick.png" />Guaranteed 4000 Reads in 12 months</li>
                </ul>
                <h5><b>Note:</b> The Amazon Prime Package guarantees you at least $40,000 in revenue in the
                  first year after the book is published, and if we fail to meet this goal, we will fully
                  refund your money</h5><a class="various" data-fancybox="" data-src="#popupform" onClick={onOpenModal}
                    href="javascript:;">Let's get started</a>
              </div>
            </div>
            <div class="col-md-4 col-lg-3 px-1">
              <div>
                <div class="title-pkg blue-t">
                  <h3>Amazon Diamond Package</h3>
                  <h4>$17,500 <ins>$35,000</ins></h4>
                  <div class="blue-grad" id="grad1"></div>
                </div>
                <ul>
                  <li><img alt="" src="assets/images/greentick.png" />Writing &amp; Rewriting of up to 500 Pages
                  </li>
                  <li><img alt="" src="assets/images/greentick.png" />Unlimited Revisions</li>
                  <li><img alt="" src="assets/images/greentick.png" />6 Months of Marketing, Including SEO
                    (Search Engine Optimization)</li>
                  <li><img alt="" src="assets/images/greentick.png" />6 Months of Social Media Marketing
                    (Facebook, Twitter, Instagram)</li>
                  <li><img alt="" src="assets/images/greentick.png" />Audio Book up to 3 hours</li>
                  <li><img alt="" src="assets/images/greentick.png" />Audio Book Publishing on Audible.</li>
                  <li><img alt="" src="assets/images/greentick.png" />Book Launch Globally</li>
                  <li><img alt="" src="assets/images/greentick.png" />Full Ownership Rights</li>
                  <li><img alt="" src="assets/images/greentick.png" />Planning / Creating/updating the outline
                  </li>
                  <li><img alt="" src="assets/images/greentick.png" />Ghostwriting original content based on
                    interviews or research</li>
                  <li><img alt="" src="assets/images/greentick.png" />Completion of your book as per the
                    requirements/outline.</li>
                  <li><img alt="" src="assets/images/greentick.png" />Revisions to our writing per your feedback
                    and guidance.</li>
                  <li><img alt="" src="assets/images/greentick.png" />Meticulous editing to ensure your text is
                    free of error.</li>
                  <li><img alt="" src="assets/images/greentick.png" />Adjusting the layout and presentation for
                    the publishing standards</li>
                  <li><img alt="" src="assets/images/greentick.png" />Add Up to 70-60 Custom Illustrations.</li>
                  <li><img alt="" src="assets/images/greentick.png" />Adjusting the layout and presentation for
                    the publishing standards.</li>
                  <li><img alt="" src="assets/images/greentick.png" />Unlimited revisions.</li>
                  <li><img alt="" src="assets/images/greentick.png" />An attention-grabbing Book Cover.</li>
                  <li><img alt="" src="assets/images/greentick.png" />Turnaround Time of 60 -80 Days.</li>
                  <li><img alt="" src="assets/images/greentick.png" />Rights to make changes to your book.</li>
                  <li><img alt="" src="assets/images/greentick.png" />Editing, Proofreading, and formatting.
                  </li>
                  <li><img alt="" src="assets/images/greentick.png" />Author Website</li>
                  <li><img alt="" src="assets/images/greentick.png" />Intro Book Video(Video Trailer)</li>
                  <li><img alt="" src="assets/images/greentick.png" />Authors Bio.</li>
                  <li><img alt="" src="assets/images/greentick.png" />Pitching article ideas to the press.</li>
                  <li><img alt="" src="assets/images/greentick.png" />Publishing on Amazon Kindle, Barnes &amp;
                    Noble, Kobo, Ingram Spark, iBook, and Lulu (All Platforms)</li>
                  <li><img alt="" src="assets/images/greentick.png" />Available in 3 different formats E-Book,
                    and Paper Back, Hardcover</li>
                  <li><img alt="" src="assets/images/greentick.png" />Amazon will be responsible for printing
                    and shipping your book globally</li>
                  <li><img alt="" src="assets/images/greentick.png" />Guaranteed 6000 Reads in 12 months</li>
                </ul>
                <h5><b>Note:</b> The Amazon Diamond Package guarantees you at least $60,000 in revenue in the
                  first year after the book is published, and if we fail to meet this goal, we will fully
                  refund your money.</h5><a class="various" data-fancybox="" data-src="#popupform" onClick={onOpenModal}
                    href="javascript:;">Let's get started</a>
              </div>
            </div>
            <div class="col-md-4 col-lg-3 px-1">
              <div>
                <div class="title-pkg green-t">
                  <h3>Amazon Platinum Package</h3>
                  <h4>$37,500 <ins>$70,000</ins></h4>
                  <div class="blue-grad" id="grad1"></div>
                </div>
                <ul>
                  <li><img alt="" src="assets/images/greentick.png" />Unlimited Pages</li>
                  <li><img alt="" src="assets/images/greentick.png" />Unlimited Revisions</li>
                  <li><img alt="" src="assets/images/greentick.png" />12 Months of Marketing, Including SEO
                    (Search Engine Optimization)</li>
                  <li><img alt="" src="assets/images/greentick.png" />12 Months of Social Media Marketing
                    (Facebook, Twitter, Instagram)</li>
                  <li><img alt="" src="assets/images/greentick.png" />Audio Book up to 6 hours</li>
                  <li><img alt="" src="assets/images/greentick.png" />Audio Book Publishing on Audible etc</li>
                  <li><img alt="" src="assets/images/greentick.png" />Book Launch Globally</li>
                  <li><img alt="" src="assets/images/greentick.png" />Full Ownership Rights</li>
                  <li><img alt="" src="assets/images/greentick.png" />Planning / Creating/updating the outline
                  </li>
                  <li><img alt="" src="assets/images/greentick.png" />Ghostwriting original content based on
                    interviews or research</li>
                  <li><img alt="" src="assets/images/greentick.png" />Completion of your book as per the
                    requirements/outline.</li>
                  <li><img alt="" src="assets/images/greentick.png" />Revisions to our writing per your feedback
                    and guidance.</li>
                  <li><img alt="" src="assets/images/greentick.png" />Meticulous editing to ensure your text is
                    free of error.</li>
                  <li><img alt="" src="assets/images/greentick.png" />Adjusting the layout and presentation for
                    the publishing standards</li>
                  <li><img alt="" src="assets/images/greentick.png" />Add Up to 100 Custom Illustrations.</li>
                  <li><img alt="" src="assets/images/greentick.png" />Adjusting the layout and presentation for
                    the publishing standards.</li>
                  <li><img alt="" src="assets/images/greentick.png" />Unlimited revisions.</li>
                  <li><img alt="" src="assets/images/greentick.png" />An attention-grabbing Book Cover.</li>
                  <li><img alt="" src="assets/images/greentick.png" />Turnaround Time of 60 -80 Days.</li>
                  <li><img alt="" src="assets/images/greentick.png" />Rights to make changes to your book.</li>
                  <li><img alt="" src="assets/images/greentick.png" />Editing, Proofreading, and formatting.
                  </li>
                  <li><img alt="" src="assets/images/greentick.png" />Author Website</li>
                  <li><img alt="" src="assets/images/greentick.png" />Intro Book Video(Video Trailer)</li>
                  <li><img alt="" src="assets/images/greentick.png" />Authors Bio.</li>
                  <li><img alt="" src="assets/images/greentick.png" />Pitching article ideas to the press.</li>
                  <li><img alt="" src="assets/images/greentick.png" />Publishing on Amazon Kindle, Barnes &amp;
                    Noble, Kobo, Ingram Spark, iBook, and Lulu (All Platforms)</li>
                  <li><img alt="" src="assets/images/greentick.png" />Available in 3 different formats E-Book,
                    and Paper Back, Hardcover</li>
                  <li><img alt="" src="assets/images/greentick.png" />Amazon will be responsible for printing
                    and shipping your book globally</li>
                  <li><img alt="" src="assets/images/greentick.png" />Guaranteed 12,000 Reads in 12 months</li>
                </ul>
                <h5><b>Note:</b> The Amazon Platinum Package guarantees you at least $120,000 in revenue in the
                  first year after the book is published, and if we fail to meet this goal, we will fully
                  refund your money.</h5><a class="various" data-fancybox="" data-src="#popupform" onClick={onOpenModal}
                    href="javascript:;">Let's get started</a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="features-col">
        <div class="container">
          <h2>Our premium ghostwriting and publishing services are aimed at assisting you to achieve your professional
            writing and publishing goals.</h2><img alt="" src={FeaturesImg} />
        </div>
      </section>

      <section class="cta-book">
        <div class="container">
          <div class="row">
            <div class="col-md-5"><img alt="" src={CtaBook} /></div>
            <div class="col-md-7">
              <p>We'll help you write the book that could be the next best seller.</p>
              <h2>Get your best seller written at <span>85%</span> OFF</h2>
              <ul>
                <li><i class="fa fa-envelope"></i><span>DISCUSS YOUR IDEAS</span>
                  <a class="email-cta" href="{EMAIL_HREF}"> {EMAIL}</a>
                </li>


              </ul>
              <a class="various" data-fancybox="" data-src="#popupform" onClick={onOpenModal} href="javascript:;" id="0" name="0"
                title="Activate Coupon Now">Activate Coupon Now</a>
              <a class="various" href={PHONE_HREF}>{PHONE}</a>
            </div>
          </div>
        </div>
      </section>

      <section class="testimonail">
        <div class="container">
          <div class="row">
            <div class="col-md-6">
              <h2>Our statured client list is a testament to our dedication.</h2>
              <p>This is what we have been doing for decades and that is why we are considered the best in
                business.</p>
            </div>
            <div class="col-md-6">
              <TestimonialSlider />
            </div>
          </div>
        </div>
      </section>

      <section class="brands">
        <div class="container">
          <ul>
            <li class="first"><img alt="" src={BtmLogo1} /></li>
            <li><img alt="" src={BtmLogo2} /></li>
            <li><img alt="" src={BtmLogo3} /></li>
            <li><img alt="" src={BtmLogo4} /></li>
            <li><img alt="" src={BtmLogo5} /></li>
            <li class="last"><img alt="" src={BtmLogo6} /></li>
          </ul>
        </div>
      </section>

      <footer>
        <div class="container">
          <div class="row">
            <div class="col-md-5">
              <img alt="" class="header-logo" src={Logo} />
              <p>{WEBSITE_NAME} has brilliant writers to accommodate all genres, from fiction novels
                to business books, memoirs to self-help guides. We also provide turnkey publishing services for
                authors globally.
              </p>
            </div>
            <div class="col-md-7 text-right">
              <ul>
                <li><i class="fa fa-envelope"></i><a href="{EMAIL_HREF}">{EMAIL}</a></li>
                <li><i class="fa fa-phone"></i> <a class="various" href={PHONE_HREF}>{PHONE}</a></li>

              </ul>
            </div>
          </div>
        </div>
        <div class="copyright">
          <div class="container">
            <p>{WEBSITE_NAME}. All Rights Reserved.</p><i class="top-scroll"><img alt=""
              src={TopScroll} /></i>
          </div>
        </div>
      </footer>

      <ModalSection open={open} onCloseModal={onCloseModal} />
    </>
  );
}

export default App;
