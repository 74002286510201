import React, { useState, useEffect } from 'react';

const Countdown = () => {
  // Calculate the remaining time
  const calculateTimeLeft = () => {
    const now = new Date();
    const endTime = new Date(localStorage.getItem('endTime') || now.getTime() + 2 * 60 * 60 * 1000);
    const difference = endTime - now;

    if (difference <= 0) {
      return { hours: 0, minutes: 0, seconds: 0 };
    }

    return {
      hours: Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
      minutes: Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60)),
      seconds: Math.floor((difference % (1000 * 60)) / 1000),
    };
  };

  // Set the end time in local storage if not already set
  useEffect(() => {
    const now = new Date();
    const endTime = new Date(now.getTime() + 2 * 60 * 60 * 1000);
    localStorage.setItem('endTime', endTime);
  }, []);

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  return (
    <div className="countdown">
      <div className="time-unit">
        <div className="time-value">{String(timeLeft.hours).padStart(2, '0')}</div>
        <div className="time-label">Hours</div>
      </div>
      <div className="time-unit">
        <div className="time-value">{String(timeLeft.minutes).padStart(2, '0')}</div>
        <div className="time-label">Minutes</div>
      </div>
      <div className="time-unit">
        <div className="time-value">{String(timeLeft.seconds).padStart(2, '0')}</div>
        <div className="time-label">Seconds</div>
      </div>
    </div>
  );
};

export default Countdown;
